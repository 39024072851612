export interface PaymentType {
    id: number;
    idUsuario: number;
    idDealer: number;
    paymentTypeName: string;
    PaymentTypeCode: number;
    price: number;
    isDefault: boolean;
}
export interface PendingRequest {
    url: string;
    method: string;
    data: {
        lat: number;
        lng: number;
        date: Date;
    };
    shiftUid: number;
}

export interface Parameter {
    id?: number;
    value: string;
    title?: string;
    extra?: string;
}
export interface ShittyParameter {
    id?: number;
    idDato?: number;
    nombre: string;
}

export interface ShittyParameter {
    idDato?: number;
    nombre: string;
}

export interface TimeOffRequest {
    id: number;
    employee: User;
    status: number;
    type: Parameter;
    dateFrom: Date;
    dateTo: Date;
    dateRequested: Date;
    dateResponse: Date;
    authorizedBy: User;
    response: number;
}

export interface User {
    id: number;
    internalCode: number;
    name: string;
    email: string;
    logo: string;
    roles: Role[];
    dealers?: Dealer[];
    isDetailer: boolean;
    token: string;
    provider: any;
    isChecked?: boolean;
    status: boolean;
    permissions?: number[];
    shiftStatus?: string;
    company?: Dealer;
}

export interface Shift {
    id: number;
    idUser: number;
    idDealer: number;
    idDealerProvider: number;
    date: string[];
    punchIn: Date;
    punchInLat: number;
    punchInLng: number;
    punchOut: Date;
    punchOutLat: number;
    punchOutLng: number;
    breakStart: Date;
    breakStartLat: number;
    breakStartLng: number;
    breakEnd: Date;
    breakEndLat: number;
    breakEndLng: number;
    idPaymentType: number;
    punchInNote: string;
    punchOutNote: string;
    breakEndNote: string;
    breakStartNote: string;
}

export interface ShiftResponse {
    id: number;
    idUser: number;
    idDealer: number;
    idDealerProvider: number;
    date: string;
    punchIn: string;
    punchInLat: number;
    punchInLng: number;
    punchOut: string;
    punchOutLat: number;
    punchOutLng: number;
    breakStart: string;
    breakStartLat: number;
    breakStartLng: number;
    breakEnd: string;
    breakEndLat: number;
    breakEndLng: number;
    idPaymentType: number;
    punchInNote: string;
    punchOutNote: string;
    breakEndNote: string;
    breakStartNote: string;
}

export const responseToShift = (response: ShiftResponse): Shift => {
    if (!response) {
        return null;
    }
    return {
        ...response,
        date: response.date ? response.date.split('-') : null,
        punchIn: new Date(response.punchIn),
        punchOut: response.punchOut ? new Date(response.punchOut) : null,
        breakStart: response.breakStart ? new Date(response.breakStart) : null,
        breakEnd: response.breakEnd ? new Date(response.breakEnd) : null,
        idPaymentType: +response.idPaymentType,
    };
};

export interface ShittyUser {
    id: number;
    codigoInterno: number;
    nombre: string;
    email: string;
    password: string;
    celular: string;
    rol: any[];
    roles: ShittyRole[];
    rolesRel: any[];
    contratistas: ShittyDealer[];
    empresa: any;
    fechaCreacion: string;
    fechaUpdate: string;
    logoImg: string;
    isDetailer: boolean;
    token: string;
    estado: string;
    contratistaOwner: ShittyDealer;
}

export interface ShittyRole {
    id: number;
    nombre: string;
    dealer: ShittyDealer;
}

export interface Role {
    id: number;
    name: string;
    dealer: Dealer;
}

export interface ShittyDealer {
    id: number;
    logoImg: string;
    razonSocial: string;
    lat: string;
    lng: string;
    radio: string;
    permisos: any[];
    geoFence: { lat: number; lng: number; radio: number }[];
    hasUserAllowChgPaymentType: number;
    isTtkAppReadonly: number;
    hasOverNight: number;
}

export interface ShittyDealerAsigned {
    id: number;
    logoImg: string;
    razonSocial: string;
}

export interface Dealer {
    id: number;
    logo: string;
    name: string;
    active?: boolean;
    lat: number;
    lng: number;
    radius: number;
    permissions: number[];
    geoFences: {
        lat: number;
        lng: number;
        radius: number;
    }[];
    hasUserAllowChgPaymentType: boolean;
    isTtkAppReadonly: boolean;
    hasOverNight: boolean;
}

export const pad = (n: number) => {
    if (n < 10) {
        return '0' + n;
    }
    return n;
};

export const toStringDate = (date: Date, withoutTime?: boolean): string => {
    return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        (!withoutTime ? ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) : '')
    );
};

export const toDateFromString = (date: string): Date => {
    if (!date) {
        return null;
    }
    if (date.indexOf('T') > -1) {
        return new Date(date);
    }
    const parts = date.split(' ');
    return parts.length > 1 ? new Date(`${parts[0]}T${parts[1]}Z`) : new Date(`${parts[0]}T00:00:00`);
};

export const convertFromShittyParameter = (r: ShittyParameter): Parameter => {
    if (!r) {
        return { value: '' };
    }
    return {
        id: r.idDato,
        value: r.nombre,
    };
};

export const convertToShittyParameter = (r: Parameter): ShittyParameter => {
    if (!r) {
        return null;
    }
    return {
        idDato: r.id,
        nombre: r.value,
    };
};

export const checkLogo = (logo: string) => {
    if (!logo || logo.includes('emptylogo')) {
        return null;
    } else {
        return logo;
    }
};

export const convertShittyDealer = (r: ShittyDealer, permisos?: { id: string }[]): Dealer => {
    if (!r) {
        return null;
    }
    permisos = permisos ? permisos : r.permisos;
    return {
        id: r.id,
        name: r.razonSocial,
        logo: checkLogo(r.logoImg),
        lat: Number(r.lat),
        lng: Number(r.lng),
        radius: Number(r.radio),
        permissions: permisos && permisos.map((p) => +p.id),
        geoFences: r.geoFence
            ? r.geoFence.map((f) => ({
                  radius: Number(f.radio),
                  lat: Number(f.lat),
                  lng: Number(f.lng),
              }))
            : [],
        hasUserAllowChgPaymentType: r.hasUserAllowChgPaymentType === 1 ? true : false,
        isTtkAppReadonly: +r.isTtkAppReadonly === 1 ? true : false,
        hasOverNight: r.hasOverNight ? true : false,
    };
};

export const convertShittyRole = (r: ShittyRole): Role => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        name: r.nombre,
        dealer: convertShittyDealer(r.dealer),
    };
};

export const convertShittyUser = (r: ShittyUser): User => {
    if (!r) {
        return null;
    }
    let roles;
    let dealers;
    let provider;
    if (r.roles) {
        roles = r.roles.map((rl) => convertShittyRole(rl));
        if (r.isDetailer) {
            dealers = r.rolesRel.map((rl) => convertShittyDealer(rl.dealerAsigned));
            provider = r.rolesRel.map((rl) => convertShittyDealer(rl.rol.companiaOrigen, rl.rol.permisos));
        } else {
            dealers = r.rolesRel.map((rl) => convertShittyDealer(rl.rol.dealer)).slice(0, 1);
            provider = r.rolesRel.map((rl) => convertShittyDealer(rl.rol.companiaOrigen, rl.rol.permisos)).slice(0, 1);
        }
    }
    return {
        id: r.id,
        internalCode: r.codigoInterno,
        name: r.nombre,
        email: r.email,
        roles,
        logo: r.logoImg,
        dealers,
        isDetailer: r.isDetailer,
        token: r.token,
        provider,
        status: r.estado === '1' ? true : false,
        company: r.contratistaOwner && convertShittyDealer(r.contratistaOwner),
    };
};

export const getEmployeeFromRequest = (r: ShittyUser): User => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        internalCode: r.codigoInterno,
        name: r.nombre,
        email: r.email,
        roles: null,
        logo: null,
        dealers: null,
        isDetailer: null,
        token: null,
        provider: null,
        isChecked: false,
        status: r.estado === '1' ? true : false,
    };
};

export const getTimeOffFromResponse = (r: any): TimeOffRequest => {
    if (!r) {
        return null;
    }
    return {
        id: +r.id,
        employee: getEmployeeFromRequest(r.author),
        dateFrom: toDateFromString(r.dateFromRequest),
        dateTo: toDateFromString(r.dateToRequest),
        dateRequested: toDateFromString(r.dateRequested),
        dateResponse: toDateFromString(r.dateResponse),
        authorizedBy: getEmployeeFromRequest(r.authorizator),
        status: r.estado,
        response: +r.typeResponse,
        type: { id: r.typeRequest?.idDato, value: r.typeRequest?.nombre, extra: r.typeRequest?.valor },
    };
};

export const getPaymentTypeFromResponse = (r: any): PaymentType => {
    if (!r) {
        return null;
    }
    return {
        id: +r.id,
        idUsuario: +r.idUsuario,
        idDealer: +r.idDealer,
        paymentTypeName: r.paymentTypeName,
        PaymentTypeCode: +r.PaymentTypeCode,
        price: +r.price,
        isDefault: r.isDefault === 1 ? true : false,
    };
};
