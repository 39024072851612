import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, IAppConfig } from '../../app.config';
import { User, convertShittyUser, Dealer, convertShittyDealer } from 'src/app/models';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { GlobalEvents } from 'src/app/services/events.service';
import { BaseService } from '../BaseService';
import { StorageService } from 'src/app/services/storage.service';

export interface UserSignUpRequest extends User {
    tipo: 'create_mobile';
    id_usuario?: number;
}

export interface UserSignInRequest {
    dealerKey?: string;
    user?: string;
    password?: string;
    codigoInterno?: string;
}

@Injectable()
export class AuthService extends BaseService {
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        http: HttpClient,
        private storage: StorageService,
        private translate: TranslateService,
        alertController: AlertController,
        globalEvents: GlobalEvents
    ) {
        super(http, globalEvents, alertController);
        AuthService.config = config;
        AuthService.storage = this.storage;
        this.initService();
    }
    public static token: string = null;
    public static storage: StorageService;
    public static config: IAppConfig;
    public static deviceModel: string;
    public static pushId: string;
    error: string;
    user: User = null;
    client: any = null;
    activeDealer: Dealer;
    activeProvider: any;
    public userEndpoint = `${this.config.apiEndpoint}modulos/mobile/json.usuario.php`;
    public static async getToken() {
        return await AuthService.storage.get('token');
    }
    async initService() {
        AuthService.token = await this.storage.get('token');
        const u = await this.storage.get('profile');
        this.user = u;
        if (u) {
            const pushId = await this.storage.get('pushId');
            if (pushId) {
                AuthService.pushId = pushId;
                this.updatePush(pushId);
            }
        }
    }
    public async updatePush(pushId: string) {
        if (this.user && this.user.id) {
            await this.apiPost(`${this.config.nodeEndpoint}/users/${this.user.id}/update_push?hide_loading`, {
                pushId,
                appName: this.config.appName,
            });
        }
        return;
    }

    public getUserStatus(skipPush?: boolean) {
        if (!skipPush) {
            this.storage.get('pushId').then((pushId) => {
                if (pushId) {
                    this.updatePush(pushId);
                }
            });
        }
        return new Promise((resolve, reject) => {
            this.http.get(`${this.config.nodeEndpoint}/users/${this.user.id}/validate_status?hide_loading`).subscribe(
                async (resp: any) => {
                    resolve(resp);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    public async setUserInList(user: User) {
        let users: { [key: string]: User } = await this.storage.get('userList');
        if (!users) {
            users = {};
        }
        users[user.id] = user;
        this.storage.set('userList', users);
    }

    public async changeUserFromList(id: number) {
        const users: { [key: string]: User } = await this.storage.get('userList');
        if (users[id]) {
            this.user = users[id];
            AuthService.token = this.user.token;
            this.storage.set('profile', this.user);
            this.storage.set('token', this.user.token);
            return this.user;
        }
        return null;
    }

    public async removeUserFromList(id: number) {
        const users: { [key: string]: User } = await this.storage.get('userList');
        if (id && users && users[id]) {
            delete users[id];
            this.storage.set('userList', users);
            if (Object.keys(users).length) {
                return await this.changeUserFromList(Object.values(users)[0].id);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public async getCompaniesFromUserList() {
        const users: { [key: string]: User } = await this.storage.get('userList');
        if (users) {
            return Object.values(users).map((u) => ({ company: u.company.name, id: u.id }));
        } else {
            await this.login({
                codigoInterno: this.user.internalCode + '',
            });
            return [{ company: this.user.company.name, id: this.user.id }];
        }
    }

    public login(credentials: UserSignInRequest, ignorePush?: boolean): Promise<User> {
        return new Promise((resolve, reject) => {
            this.http.post(`${this.config.apiEndpoint}/php/api/login.php`, credentials).subscribe(
                async (resp: any) => {
                    if (!ignorePush) {
                        this.storage.get('pushId').then((pushId) => {
                            this.updatePush(pushId);
                        });
                    }
                    let isAdmin = false;
                    resp.data.roles.forEach((r) => {
                        if (Number(r.id) === 2) {
                            isAdmin = true;
                        }
                    });
                    if (!isAdmin) {
                        this.user = convertShittyUser(resp.data);
                        AuthService.token = this.user.token;
                        this.storage.set('profile', this.user);
                        this.storage.set('token', this.user.token);
                        this.setUserInList(this.user);
                        resolve(this.user);
                    } else {
                        reject({ msg: 'INVALID_USER_ROLE' });
                    }
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    public async logout(ignorePush?: boolean) {
        const user = await this.removeUserFromList(this.user.id);
        if (user) {
            this.setActiveDealer(this.user.dealers[0], 0);
            return true;
        } else {
            if (!ignorePush) {
                await this.updatePush(null);
            }
            await this.emptyStorage();
            this.activeDealer = this.activeProvider = this.user = this.client = null;
            return false;
        }
    }

    public async getUser() {
        if (!this.user) {
            this.user = await this.storage.get('profile');
        }
        return this.user;
    }

    public getProfileId() {
        return this.user.id;
    }

    public authenticated() {
        return this.storage.get('profile').then((t) => {
            if (t && t.id) {
                return true;
            } else {
                return false;
            }
        });
    }

    async getActiveDealer() {
        if (!this.activeDealer) {
            this.activeDealer = await this.storage.get('activeDealer');
        }
        return this.activeDealer;
    }

    async getActiveProvider() {
        if (!this.activeProvider) {
            this.activeProvider = await this.storage.get('activeProvider');
        }
        return this.activeProvider;
    }

    async emptyStorage() {
        await this.storage.clear();
        await this.storage.set('pushId', AuthService.pushId);
    }

    async getActiveIds() {
        const user = await this.getUser();
        const provider = await this.getActiveProvider();
        const dealer = await this.getActiveDealer();
        return {
            idUsuario: user && user.id,
            idDealer: dealer && dealer.id,
            idDealerProvider: provider && provider.id,
        };
    }

    setActiveProvider(provider: Dealer) {
        const uploadsFolder = `${this.config.apiEndpoint}/uploads/`;
        this.activeProvider = provider;
        this.activeProvider.logo =
            this.activeProvider.logo && !this.activeProvider.logo.includes('http')
                ? `${uploadsFolder}${this.activeProvider.logo}`
                : this.activeProvider.logo;
        this.storage.set('activeProvider', this.activeProvider);
        return this.activeProvider;
    }

    setActiveDealer(dealer: Dealer, pos?: number) {
        const uploadsFolder = `${this.config.apiEndpoint}/uploads/`;
        dealer.logo = dealer.logo && !dealer.logo.includes('http') ? `${uploadsFolder}${dealer.logo}` : dealer.logo;
        this.activeDealer = dealer;
        this.storage.set('activeDealer', dealer);
        if (pos !== undefined) {
            this.setActiveProvider(this.user.provider[pos]);
        }
        return dealer;
    }

    async getRemoteDealer(id: number) {
        const { data } = await this.apiGet(
            `${this.config.apiEndpoint}/php/api/api.dealers.php?id_dealer=${id}&hide_loading`
        );
        return convertShittyDealer(data);
    }

    async dealerSelect(dealers: Dealer[], resolve: any) {
        this.translate.get(['SELECT_DEALER', 'ACCEPT', 'CANCEL']).subscribe(async (res) => {
            const inputs = [];
            for (let i = 0; i < dealers.length; i++) {
                const element = dealers[i];
                inputs.push({
                    name: `car${i}`,
                    type: 'radio',
                    label: `${element.name}`,
                    value: i,
                    checked: dealers[i].active,
                });
            }
            const alert = await this.alertCtrl.create({
                header: res.SELECT_DEALER,
                inputs,
                buttons: [
                    {
                        text: res.CANCEL,
                        role: 'cancel',
                    },
                    {
                        text: res.ACCEPT,
                        handler: (i: number) => {
                            const dealer = this.user.dealers[i];
                            this.setActiveDealer(dealer, i);
                            resolve(dealer);
                        },
                    },
                ],
            });
            await alert.present();
        });
    }
}
