import { Injectable, Inject } from '@angular/core';
import { IAppConfig, APP_CONFIG } from '../../app.config';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from '../BaseService';
import { AuthService } from '../auth-service/auth.service';
import { HttpClient } from '@angular/common/http';
import { GlobalEvents } from 'src/app/services/events.service';
import { AlertController } from '@ionic/angular';
import {
    getEmployeeFromRequest,
    getPaymentTypeFromResponse,
    getTimeOffFromResponse,
    Parameter,
    PaymentType,
    TimeOffRequest,
} from 'src/app/models';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
    providedIn: 'root',
})
export class InformationService extends BaseService {
    public lang: string;
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        private storage: StorageService,
        private translate: TranslateService,
        private authService: AuthService,
        http: HttpClient,
        globalEvents: GlobalEvents,
        alertController: AlertController
    ) {
        super(http, globalEvents, alertController);
    }

    async getLang() {
        if (!this.lang) {
            this.lang = await this.storage.get('lang', 'en');
        }
        return this.lang;
    }

    async getEmployees() {
        const activeIds = await this.authService.getActiveIds();
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/api.employees.php?hide_loading`, {
            ...activeIds,
            filterEmployee: 1,
            showScanner: 1,
        });
        const users = (resp.data as any[]).map((i) => getEmployeeFromRequest(i));
        return users;
    }

    async getCodeForUser(userId: number): Promise<string> {
        const resp = await this.apiGet(`${this.config.nodeEndpoint}/users/${userId}/code`);
        return resp + '';
    }

    async getTimeOffTypes(): Promise<Parameter[]> {
        const resp = await this.apiGet(`${this.config.apiEndpoint}/php/api/modulos/ttk/getDayOffTypes?hide_loading`);
        return resp.data.map((d): Parameter => ({ id: d.idDato, value: d.nombre, extra: d.valor }));
    }

    async getTimeOffList(
        userId?: number,
        id?: number,
        activeRequest?: boolean,
        responseType?: string,
        requestType?: string
    ): Promise<TimeOffRequest[]> {
        const { idDealer } = await this.authService.getActiveIds();
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/modulos/ttk/getListDayOffByEmployee/`, {
            idAuthor: userId,
            idDealer,
            id,
            requestDayFrom: activeRequest ? new Date().toISOString().split('T')[0] : null,
            typeResponse: responseType && responseType !== '99' ? +responseType : null,
            typeRequest: requestType && requestType !== '99' ? +requestType : null,
        });
        return resp.data.map((r) => getTimeOffFromResponse(r));
    }

    async requestTimeOff(typeRequest: number, dateFrom: string, dateTo: string) {
        const activeIds = await this.authService.getActiveIds();
        const resp = await this.apiPut(`${this.config.apiEndpoint}/php/api/modulos/ttk/request`, {
            idDealer: activeIds.idDealer,
            typeRequest,
            dateFrom: dateFrom.split('T')[0],
            dateTo: dateTo.split('T')[0],
        });
        return resp;
    }

    async timeOffResponse(id: number, typeResponse: number) {
        const resp = await this.apiPut(`${this.config.apiEndpoint}/php/api/modulos/ttk/response`, {
            id,
            typeResponse,
        });
        return resp;
    }

    changeLang(lang: string) {
        this.translate.use(lang);
        this.storage.set('lang', lang);
        this.lang = lang;
    }

    async getPaymentType(user?: number): Promise<PaymentType[]> {
        const { idDealer, idUsuario } = await this.authService.getActiveIds();
        const url = `/php/api/modulos/usuarios/getPaymentsType/${idDealer}/${user ? user : idUsuario}`;
        //const url =  '/php/api/modulos/usuarios/getPaymentsType/'+idDealer+'/'+idUsuario
        const resp = await this.apiGet(`${this.config.apiEndpoint}${url}?hide_loading`);
        return resp.data.map((r) => getPaymentTypeFromResponse(r));
    }

    async getAllPaymentTypes(): Promise<PaymentType[]> {
        const url = `/php/api/modulos/genericdata/list/30`;
        const resp = await this.apiGet(`${this.config.apiEndpoint}${url}?hide_loading`);
        return resp.data.map((r) => ({
            paymentTypeName: r.name,
            id: +r.id,
        }));
    }
}
