import { Component, OnInit } from '@angular/core';
import { AlertController, IonItemSliding, IonRouterOutlet, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Parameter, User } from 'src/app/models';
import { AuthService } from 'src/app/providers/auth-service/auth.service';
import { InformationService } from 'src/app/providers/information-service/information.service';
import { SearchModal } from '../search.helper';

@Component({
    selector: 'app-time-off-revision',
    templateUrl: './time-off-revision.component.html',
    styleUrls: ['./time-off-revision.component.scss'],
})
export class TimeOffRevisionComponent implements OnInit {
    timeOffTypes: Parameter[] = [];
    employees: User[] = [];
    requests: any[];
    searchModal: SearchModal;
    selectedEmployee: User;
    isAdmin: boolean;
    id: number;
    activeRequest = false;
    responseType: string = '99';
    requestType: string = '99';

    constructor(
        private alertCtrl: AlertController,
        private modalCtrl: ModalController,
        private informationService: InformationService,
        private translateService: TranslateService,
        private authService: AuthService
    ) {
        // const numToday = Date.now();
        // this.dateTo = new Date(numToday + 86400000 * 1).toISOString();
        this.searchModal = new SearchModal(this.modalCtrl, null);
    }

    async ngOnInit() {
        if (!this.isAdmin) {
            this.selectedEmployee = await this.authService.getUser();
        }
        this.timeOffTypes = await this.informationService.getTimeOffTypes();
        await this.searchRequests();
    }

    async selectEmployee() {
        this.selectedEmployee = await this.searchModal.searchEmployee({
            sourceList: this.employees,
            initSelected: this.selectedEmployee?.id,
            allowEmpty: true,
        });
        this.searchRequests();
    }

    async searchRequests() {
        this.requests = await this.informationService.getTimeOffList(
            this.selectedEmployee?.id,
            this.id,
            this.activeRequest,
            this.responseType,
            this.requestType
        );
        console.log(this.requests);
    }

    async sendRequestResponse(id: number, typeResponse: number, slidingItem: IonItemSliding) {
        slidingItem.closeOpened();
        await this.informationService.timeOffResponse(id, typeResponse);
        await this.searchRequests();
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
