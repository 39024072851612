import { ModalController, IonRouterOutlet } from '@ionic/angular';
import { Dealer, Parameter, User } from '../models';
import { FilterSelectorComponent } from './filter-selector/filter-selector.component';

export class SearchModal {
    modalController: ModalController;
    constructor(modalController: ModalController, private routerOutlet: IonRouterOutlet) {
        this.modalController = modalController;
    }

    async searchEmployee(componentProps?: {
        sourceList: User[];
        initSelected?: number;
        multiple?: boolean;
        multipleSelected?: Partial<User>[];
        allowEmpty?: boolean;
    }): Promise<any> {
        const modal = await this.modalController.create({
            component: FilterSelectorComponent,
            componentProps: { ...componentProps, filterField: 'name' },
            swipeToClose: false,
            presentingElement: this.routerOutlet.nativeEl,
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        return data;
    }

    async selectDealer(componentProps?: {
        sourceList: Dealer[];
        initSelected?: number;
        multiple?: boolean;
        multipleSelected?: Partial<Parameter>[];
        title: string;
    }): Promise<Parameter[] | { dealer: Dealer; pos: number }> {
        const modal = await this.modalController.create({
            component: FilterSelectorComponent,
            componentProps: { ...componentProps, filterField: 'name' },
            swipeToClose: false,
            presentingElement: this.routerOutlet.nativeEl,
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (!componentProps.multiple && data) {
            return { dealer: data.found, pos: data.index };
        } else {
            return data;
        }
    }

    async selectParameter(componentProps?: {
        sourceList: Parameter[];
        initSelected?: number;
        multiple?: boolean;
        multipleSelected?: Partial<Parameter>[];
        maxlength?: number;
        enableCreation?: boolean;
        isNumeric?: boolean;
        transformValue?: (value: string) => string;
        asyncSearch?: (query: string) => Promise<Parameter[]>;
    }): Promise<Parameter> {
        const modal = await this.modalController.create({
            component: FilterSelectorComponent,
            componentProps: { ...componentProps, filterField: 'value' },
            swipeToClose: false,
            presentingElement: this.routerOutlet.nativeEl,
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        return data && data.found;
    }

    async selectCompany(componentProps?: {
        sourceList: { company: string; id: number }[];
        initSelected: number;
        title: string;
    }): Promise<number> {
        const modal = await this.modalController.create({
            component: FilterSelectorComponent,
            componentProps: { ...componentProps, filterField: 'company' },
            swipeToClose: false,
            presentingElement: this.routerOutlet.nativeEl,
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (data) {
            return data.found.id;
        } else {
            return null;
        }
    }
}
