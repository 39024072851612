import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionSheetController, PopoverController, AlertController, NavController } from '@ionic/angular';
import { InformationService } from '../../providers/information-service/information.service';
import { AuthService } from '../../providers/auth-service/auth.service';
import { User } from '../../models';
import { GlobalEvents } from '../../services/events.service';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
    user: User;
    hasUserListPermission: boolean;
    appVersion: string;
    constructor(
        @Inject(APP_CONFIG) config: IAppConfig,
        private translate: TranslateService,
        public actionSheetCtrl: ActionSheetController,
        private popoverController: PopoverController,
        private authService: AuthService,
        private navCtrl: NavController,
        public alertController: AlertController,
        private informationService: InformationService,
        private events: GlobalEvents
    ) {
        this.appVersion = config.appVersion;
    }

    async ngOnInit() {
        this.user = await this.authService.getUser();
    }

    dismissPopover() {
        this.popoverController.dismiss();
    }

    presentActionSheet() {
        this.dismissPopover();
        this.translate.get(['LANGUAGE', 'ENGLISH', 'SPANISH', 'CANCEL']).subscribe(async (res) => {
            const actionSheet = await this.actionSheetCtrl.create({
                header: res.LANGUAGE,
                buttons: [
                    {
                        text: res.ENGLISH,
                        handler: () => {
                            this.changeLang('en');
                        },
                    },
                    {
                        text: res.SPANISH,
                        handler: () => {
                            this.changeLang('es');
                        },
                    },
                    {
                        text: res.CANCEL,
                        role: 'cancel',
                    },
                ],
            });
            actionSheet.present();
        });
    }

    async logout() {
        const t = await this.translate.get(['LOGOUT', 'CANCEL', 'ACCEPT', 'LOGOUT_WARNING']).toPromise();
        const alert = await this.alertController.create({
            header: t.LOGOUT,
            message: t.LOGOUT_WARNING,
            buttons: [
                {
                    text: t.CANCEL,
                    role: 'cancel',
                },
                {
                    text: t.ACCEPT,
                    handler: async () => {
                        this.events.publish({ event: 'LOGOUT', data: null });
                    },
                },
            ],
        });
        this.dismissPopover();
        await alert.present();
    }

    async changeDealer() {
        this.events.publish({ event: 'change_dealer_modal', data: null });
        this.dismissPopover();
    }

    changeLang(lang) {
        this.informationService.changeLang(lang);
    }

    gotToShiftHistory() {
        this.dismissPopover();
        this.navCtrl.navigateForward('/shift-history');
    }

    gotToUserList() {
        this.dismissPopover();
        this.navCtrl.navigateForward('/user-list');
    }

    gotToTimeOffHistory() {
        this.events.publish({ event: 'go_to_time_off_history', data: null });
        this.dismissPopover();
    }

    gotToTimeOffRequest() {
        this.events.publish({ event: 'go_to_time_off_request', data: null });
        this.dismissPopover();
    }

    changeCompany() {
        this.events.publish({ event: 'change_company_modal', data: null });
        this.dismissPopover();
    }
}
