import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AuthService } from './providers/auth-service/auth.service';
import { APP_CONFIG, AppConfig } from './app.config';
import { HttpService } from './interceptors/http.service';
import { SettingsComponent } from './components/settings/settings.component';
import { FilterSelectorComponent } from './modals/filter-selector/filter-selector.component';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { QrPopoverComponent } from './components/qr-popover/qr-popover.component';
import { UserListSettingsComponent } from './components/user-list-settings/user-list-settings.component';
import { MapAlertComponent } from './components/map-alert/map-alert.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { StorageService } from './services/storage.service';
import { BarcodeService } from './services/barcode.service';
import { TimeOffRequestComponent } from './modals/time-off-request/time-off-request.component';
import { FormsModule } from '@angular/forms';
import { TimeOffRevisionComponent } from './modals/time-off-revision/time-off-revision.component';
import { NotificationsPage } from './modals/notifications/notifications.page';
import { AddEditPunchModalComponent } from './modals/add-edit-punch-modal/add-edit-punch-modal.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    declarations: [
        AppComponent,
        SettingsComponent,
        FilterSelectorComponent,
        QrPopoverComponent,
        UserListSettingsComponent,
        MapAlertComponent,
        TimeOffRequestComponent,
        TimeOffRevisionComponent,
        NotificationsPage,
        AddEditPunchModalComponent,
    ],
    entryComponents: [
        SettingsComponent,
        FilterSelectorComponent,
        UserListSettingsComponent,
        MapAlertComponent,
        TimeOffRequestComponent,
        TimeOffRevisionComponent,
        NotificationsPage,
        AddEditPunchModalComponent,
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        HttpClientModule,
        IonicStorageModule.forRoot({ name: 'TTK_DB' }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        NgxQRCodeModule,
        AppRoutingModule,
        GoogleMapsModule,
        FormsModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        AuthService,
        BarcodeScanner,
        BarcodeService,
        StorageService,
        {
            provide: APP_CONFIG,
            useValue: AppConfig,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpService,
            multi: true,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        OneSignal,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
