import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { differenceInHours } from 'date-fns';
import { PaymentType, Shift } from 'src/app/models';
import { ShiftService } from 'src/app/providers/shift-service/shift.service';

@Component({
    selector: 'app-add-edit-punch-modal',
    templateUrl: './add-edit-punch-modal.component.html',
    styleUrls: ['./add-edit-punch-modal.component.scss'],
})
export class AddEditPunchModalComponent implements OnInit {
    title: string;
    shift: Shift;
    punchIn: string;
    punchOut: string;
    breakStart: string;
    breakEnd: string;
    punchInOrig: string;
    breakStartOrig: string;
    breakEndOrig: string;
    punchOutOrig: string;
    today = new Date();
    hasPaymentTypePerm: boolean;
    paymentTypes: PaymentType[] = [];
    paymentId: number;
    errors = {
        punchIn: '',
        punchInNote: '',
        punchOut: '',
        breakStart: '',
        breakEnd: '',
    };
    userId: number;
    dealerName: string;
    dealerId: number;
    constructor(private modalCtrl: ModalController, private shiftService: ShiftService) {}

    ngOnInit() {
        if (!this.shift) {
            this.shift = { idDealer: this.dealerId } as Shift;
        }
        this.punchIn = this.punchInOrig = this.shift?.punchIn?.toISOString();
        this.punchOut = this.breakStartOrig = this.shift?.punchOut?.toISOString();
        this.breakStart = this.breakEndOrig = this.shift?.breakStart?.toISOString();
        this.breakEnd = this.punchOutOrig = this.shift?.breakEnd?.toISOString();
        this.paymentId = this.shift?.idPaymentType
            ? this.shift?.idPaymentType
            : this.paymentTypes.find((pt) => pt.isDefault)?.id;
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }

    async save() {
        this.updateValues();
        if (Object.values(this.errors).find((v) => !!v)) {
            return;
        }
        const resp = await this.shiftService.updateCreateShift(this.shift, this.userId).catch((e) => {
            return null;
        });
        if (!resp) {
            return;
        }
        this.dismiss(this.shift);
    }

    updateValues() {
        this.shift.punchIn = this.punchIn ? new Date(this.punchIn) : null;
        this.shift.punchOut = this.punchOut ? new Date(this.punchOut) : null;
        this.shift.breakStart = this.breakStart ? new Date(this.breakStart) : null;
        this.shift.breakEnd = this.breakEnd ? new Date(this.breakEnd) : null;
        this.shift.idPaymentType = this.paymentId;
    }

    clearErrors() {
        this.errors = {
            punchIn: '',
            punchInNote: '',
            punchOut: '',
            breakStart: '',
            breakEnd: '',
        };
    }

    validate() {
        let res;
        this.clearErrors();
        this.updateValues();
        if (!this.shift.id && !this.shift.punchInNote) {
            res = 'Clock in note is required';
            this.errors.punchInNote = res;
        }
        if (!this.shift.punchIn) {
            res = 'Clock in is required';
            this.errors.punchIn = res;
        }
        if (this.shift.punchIn > this.today) {
            res = 'Clock in is greather than today';
            this.errors.punchIn = res;
        }
        if (this.shift.punchOut) {
            var diffInOut = differenceInHours(this.shift.punchOut, this.shift.punchIn);
            //res es un mensaje de texto que aparece en pantalla (no como alerta sino como texto en rojo alerrtando, pero no bloquea ni muestra modal ni nada
            if (this.shift.punchOut <= this.shift.punchIn) {
                res = 'Clock out must be greater than clock in';
                this.errors.punchOut = res;
            } else if (diffInOut > 20) {
                res = 'Clock out have 20 hours more than clock in';
                this.errors.punchOut = res;
            }
        }
        if (this.shift.breakStart) {
            if (this.shift.breakStart <= this.shift.punchIn) {
                res = 'Break start must be greater than clock in';
                this.errors.breakStart = res;
            }
        }
        if (this.shift.breakEnd) {
            if (this.shift.breakEnd <= this.shift.breakStart) {
                res = 'Break out must be greater than break in';
                this.errors.breakEnd = res;
            } else if (this.shift.punchOut) {
                if (this.shift.breakEnd >= this.shift.punchOut) {
                    res = 'Clock out must be greater than break out';
                    this.errors.punchOut = res;
                }
            }
        }
        return res;
    }
}
