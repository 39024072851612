import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Parameter } from 'src/app/models';
import { InformationService } from 'src/app/providers/information-service/information.service';
import { confirmAction } from 'src/app/utils';

@Component({
    selector: 'app-time-off-request',
    templateUrl: './time-off-request.component.html',
    styleUrls: ['./time-off-request.component.scss'],
})
export class TimeOffRequestComponent implements OnInit {
    timeOffTypes: Parameter[] = [];
    timeOffType: number;
    today = new Date().toISOString();
    dateFrom = this.today;
    dateTo = this.today;
    requestButtonText: string;
    dayInMilliseconds = 86400000;
    singleDay = false;
    constructor(
        private alertCtrl: AlertController,
        private modalCtrl: ModalController,
        private informationService: InformationService,
        private translateService: TranslateService
    ) {}

    async ngOnInit() {
        const text = await this.translateService.get('REQUEST_DAY_BUTTON_TEXT').toPromise();
        this.requestButtonText = text.replace('$N', 1);
        this.timeOffTypes = await this.informationService.getTimeOffTypes();
    }

    typeChanged() {
        const typeSelected = this.timeOffTypes.find((t) => t.id === this.timeOffType);
        this.singleDay = typeSelected.extra === '2';
    }

    async dateChanged() {
        const t = await this.translateService.get(['REQUEST_DAY_BUTTON_TEXT', 'REQUEST_DAYS_BUTTON_TEXT']).toPromise();
        if (this.singleDay) {
            this.requestButtonText = t['REQUEST_DAY_BUTTON_TEXT'].replace('$N', 1);
            return;
        }
        let to = new Date(this.dateTo);
        let from = new Date(this.dateFrom);
        if (from.getTime() > to.getTime()) {
            to = from;
            this.dateTo = to.toISOString();
        }
        const days =
            this.dateTo !== this.dateFrom
                ? Math.round((to.getTime() - from.getTime()) / this.dayInMilliseconds) + 1
                : 1;
        const tt = days > 1 ? 'REQUEST_DAYS_BUTTON_TEXT' : 'REQUEST_DAY_BUTTON_TEXT';
        this.requestButtonText = t[tt].replace('$N', days);
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }

    async requestTimeOff() {
        await confirmAction(
            {
                action: 'CONFIRM_TIME_OFF_REQUEST',
                callback: async () => {
                    await this.informationService.requestTimeOff(
                        this.timeOffType,
                        this.dateFrom,
                        this.singleDay ? this.dateFrom : this.dateTo
                    );
                    this.dismiss();
                },
            },
            this.translateService,
            this.alertCtrl
        );
    }
}
