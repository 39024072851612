import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/providers/notification-service/notification.service';
import { Notification } from '../../../../../srs-common/src/models';
import { Browser } from '@capacitor/browser';
import { confirmAction } from 'src/app/utils';
import { GlobalEvents } from 'src/app/services/events.service';
@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.page.html',
    styleUrls: ['./notifications.page.scss'],
})
export class NotificationsPage implements OnInit {
    notifications: Notification[] = [];
    lang: string;

    constructor(
        private modalCtrl: ModalController,
        private notificationService: NotificationService,
        private translate: TranslateService,
        private navCtrl: NavController,
        private alertCtrl: AlertController,
        private events: GlobalEvents
    ) {
        this.lang = this.translate.currentLang;
    }

    async ngOnInit() {
        this.notifications = await this.notificationService.getNotifications();
        this.notificationService.markNotificationsReadAll();
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }

    async deleteNotification(notification: Notification) {
        await this.notificationService.deleteNotification(notification.id);
        this.notifications = await this.notificationService.getNotifications();
    }

    async notificationAction(notification: Notification) {
        if (notification.type === 5) {
            this.dismiss();
            setTimeout(() => {
                this.events.publish({ event: 'go_to_time_off_history', data: notification.idTimeOff });
            }, 300);
        } else if (notification.type === 2) {
            Browser.open({ url: notification.extra });
        } else if (notification.type === 4) {
            this.showType4Alert(notification);
        }
    }

    async showType4Alert(notification) {
        await confirmAction(
            {
                action: notification.titleEn,
                message: notification.titleEn !== notification.messageEn ? notification.messageEn : null,
                yesText: notification.extra ? 'MORE_DETAILS' : 'OK',
                callback: async () => {
                    if (notification.extra) {
                        Browser.open({ url: notification.extra });
                    }
                },
            },
            this.translate,
            this.alertCtrl
        );
    }

    async doRefresh(event) {
        this.notifications = await this.notificationService.getNotifications().catch(() => {
            event.target.complete();
            return null;
        });
        event.target.complete();
    }
}
